import { Helmet } from 'react-helmet'
import React from 'react'
import { graphql } from 'gatsby'

import DocumentContainer from '../components/DocumentContainer'
import Layout from '../components/Layout'
import NewsItem from '../components/NewsItem'
import Section from '../components/Section'

export default ({ data: { newsYaml: post } }) => (
  <Layout>
    <Helmet title={post.name} />
    <DocumentContainer>
      <Section title="News" backwardLink="news">
        <NewsItem node={post} />
      </Section>
    </DocumentContainer>
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    newsYaml(fields: { slug: { eq: $slug } }) {
      name
      date
      body
    }
  }
`
